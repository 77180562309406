

<div>
  <div *ngIf="showFailure" class="lead-gen -failure">
    <notification class="-colorAccentRed" title="Error!">
      There was an error processing your inquiry. Please try again later.
    </notification>
  </div>

  <cardBasic class="-border" style="display: block;">
    <form ngNoForm [action]="formURL" method="POST" novalidate>
      <div class="d-flex flex-row" *ngFor="let question of questions; let index = index">
        <div class="d-flex lead-gen -row" *ngIf="index % 2 === 0">
          <div class="lead-gen -cols">
            <wp-form-question
              class="lead-gen -question"
              [question]="questions[index]"
              [form]="form"
              [ngClass]="questions[index].key === ('Description_Hidden__c') ? '-resize-full-width' : ''">
            </wp-form-question>
            <wp-form-question
              *ngIf="questions[index + 1]"
              class="lead-gen -question"
              [question]="questions[index + 1]"
              [form]="form">
            </wp-form-question>
          </div>
        </div>

        <div *ngIf="index === 5" style="width: 100%">
          <div class="lead-gen -cols">
            <label class="lead-gen -question -asterisk">
              <span class="-fontMedium span"> 
                Company Geography
              </span>
              <select
                formControlName="country_code"
                name="country_code"
                id="country_code"
                class="form-control placeholder"
                [ngClass]="{'error-border': form.controls['country_code'].touched 
                  && !form.controls['country_code'].value}"
                required="true"
                (change)="changeCountry($event.target.value)"
                >
                <option value="">Select Country</option>
                <option *ngFor ="let count of countryList" [value]="count.value">{{count.name}} </option>
              </select>
              <span class="form -error" *ngIf="form.controls['country_code'].touched && !form.controls['country_code'].value">
                  Please fill in this field. Company Geography is required                  
              </span>
            </label>

            <label class="lead-gen -question -asterisk">
              <div>
                <span class="-fontMedium span"> 
                  State/Province/Region 
                </span>
              </div>
              <select
                formControlName="state_code"
                name="state_code"
                id="state_code"
                class="form-control placeholder"
                [ngClass]="{'error-border': form.controls['state_code'].touched 
                  && !form.controls['state_code'].value}"
                required="true"
                (change)="changeState($event.target.value)"
              >
                <option value="">Select State/Province/Region</option>
                <option *ngFor ="let city of cities" [value]="city.value"> {{city.name}} </option>
              </select>
              <span class="form -error" *ngIf="form.controls['state_code'].touched && !form.controls['state_code'].value">
                Please fill in this field. State/province is required                  
              </span>
            </label>
            <label class="lead-gen -question -asterisk">
              <span class="-fontMedium span"> 
                Are you a current customer?
              </span>
              <select
                formControlName="Config_Field1__c"
                name="Config_Field1__c"
                id="Config_Field1__c"
                class="form-control placeholder"
                [ngClass]="{'error-border': form.controls['Config_Field1__c'].touched 
                  && !form.controls['Config_Field1__c'].value}"
                required="true"
                (change)="changeCurrentCustomer($event.target.value)"
                >
                <option value="">Select</option>
                <option *ngFor ="let customer of currentCustomer" [value]="customer.value">{{customer.name}} </option>
              </select>
              <span class="form -error" *ngIf="form.controls['Config_Field1__c'].touched && !form.controls['Config_Field1__c'].value">
                  Please fill in this field. Current customer is required                  
              </span>
            </label>

            <label *ngIf="showProductQueryDropdown"  class="lead-gen -question -asterisk">
              <span class="-fontMedium span"> 
                Do you have any inquiry?
              </span>
              <select
                formControlName="Product_Specific_Inquiry__c"
                name="Product_Specific_Inquiry__c"
                id="Product_Specific_Inquiry__c"
                class="form-control placeholder"
                [ngClass]="{'error-border': form.controls['Product_Specific_Inquiry__c'].touched 
                  && !form.controls['Product_Specific_Inquiry__c'].value}"
                required="true"
                (change)="changeProductQuries($event.target.value)"
              >
                <option value="">Select</option>
                <option *ngFor ="let productQuery of productQuries" [value]="productQuery.value"> {{productQuery.name}} </option>
              </select>
              <span class="form -error" *ngIf="form.controls['Product_Specific_Inquiry__c'].touched && !form.controls['Product_Specific_Inquiry__c'].value">
                Please fill in this field. Do you have any inquiry? is required                  
              </span>
            </label>
            <label *ngIf="showCustomerQueryDropdown" class="lead-gen -question -asterisk">
              <span class="-fontMedium span"> 
                What do you want to talk to us about?
              </span>
              <select
                formControlName="Product_Services_Interest__c"
                name="Product_Services_Interest__c"
                id="Product_Services_Interest__c"
                class="form-control placeholder"
                [ngClass]="{'error-border': form.controls['Product_Services_Interest__c'].touched 
                  && !form.controls['Product_Services_Interest__c'].value}"
                required="true"
                (change)="changeCustomerQuries($event.target.value)"
              >
                <option value="">Select</option>
                <option *ngFor ="let customerQuery of customerQuries" [value]="customerQuery.value"> {{customerQuery.name}} </option>
              </select>
              <span class="form -error" *ngIf="form.controls['Product_Services_Interest__c'].touched && !form.controls['Product_Services_Interest__c'].value">
                Please fill in this field. Customer Quries is required                  
              </span>
            </label>
            <label class="lead-gen -question" [ngClass]="resizewidth ? '-resize-width' : ''">
              <span class="-fontMedium span -question"> 
                Phone Number
              </span>
              <input type="text" class="-question"
              formControlName="phone"
              name="phone"
              id="phone"
              placeholder="Enter your Phone number"
              required="false"
              [ngClass]="{'error-border': !form.controls['phone'].valid && (form.controls['phone'].touched)}"
              (change)="changePhoneNumber($event.target.value)"
               >
              <span class="form -error" *ngIf="!form.controls['phone'].valid && (form.controls['phone'].touched)">
                Please fill in a valid phone number. E.g. +19997778888
              </span>
            </label>
          </div>
        </div>
      </div>
      <!-- Old values -->
      <!-- <input type="hidden" name="retURL" value="https://www.google.com">                             
        <input type=hidden name="oid" value="00D030000008aT9">
        <input type=hidden name="Campaign_ID" value="70103000000DLou"> -->
        
      <!-- For Debugging purposes -->
      <!-- <input type="hidden" name="debug" value=1>                             
      <input type="hidden" name="debugEmail" value="robert.iordache@fisglobal.com"> -->
      
      <!-- retURL only redirects on https -->
      <!-- <input type=hidden name="retURL" value="https://www.google.com"> -->
      <input type="hidden" name="retURL" [value]="returnRoute">

      <input type="hidden" name="oid" [value]="oid">
      <input type="hidden" name="Campaign_ID" [value]="campaignID">
      <input type="hidden" name="recordType" [value]="recordType">
      <input type="hidden" name="member_status" value="Invited" />
      <input type="hidden" name="lead_source" value="Website - DEV Engine">
      <input type="hidden" name="Reference_Information__c" [value]="reference_Information_c">
      <input type="hidden" name="submit" value="Submit">
      
      <div *ngIf='showCustomerQueryDropdown' class="lead-gen -submit-button-wrapper">
        <button id="submit" type="submit" [disabled]="form && !form.valid" class="lead-gen -submit-button" onclick="submit()">
          <div (click)="touchForm();">
            Submit
          </div>
        </button>
      </div>
   
      <div *ngIf='!showCustomerQueryDropdown' class="lead-gen -submit-button-wrapper">
        <button  type="submit" (click)="sendMail()"
          class="lead-gen -submit-button" name="submit" value="Submit">Submit</button></div>
    </form>
  </cardBasic>
</div>

