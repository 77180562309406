import { Component } from '@angular/core';
import { Globals } from 'app/globals';
import { LoaderService } from 'app/services/loader.service';
import { MetaService } from 'app/services/meta.service';

@Component({
  selector: 'app-contact-sales',
  templateUrl: './contact-sales.component.html',
})
export class ContactSalesComponent {
  filter = '';
  title = '';
  subheading = '';
  details = '';
  metadataTemplate;

  constructor(public loaderService: LoaderService, private metaService: MetaService, public globals: Globals) {}

  ngOnInit() {
    this.metaService.metadata.next({ template: '' });
    const currentUrl = window.location.href;
    //checking contact sales generic or specific to product.
    if (currentUrl.split('/').indexOf('contact-sales') === currentUrl.split('/').length - 1) {
      this.title = this.globals.CONTACT_SALES_GENERIC_TITLE;
      this.subheading = this.globals.CONTACT_SALES_GENERIC_SUBHEADING;
      this.details = this.globals.CONTACT_SALES_PRODUCT_DETAILS;
    } else {
      const productFamily = currentUrl.split('/');
      this.title = this.globals.CONTACT_SALES_PRODUCT_TITLE;
      const subheadingArray = this.globals.CONTACT_SALES_PRODUCT_SUBHEADING.split(',');
      this.subheading = subheadingArray[0] + productFamily[productFamily.length - 1] + ',' + subheadingArray[1];
      this.details = this.globals.CONTACT_SALES_PRODUCT_DETAILS;
    }
    this.loaderService.loadingContent.emit(false);
  }
}