<div class="-doc-page-wrapper">
  <div class="-content">
    <div class="-docpage-content-container" >
      <div class="sec-masthead-section" style='padding-bottom: unset;'>
        <secondary-masthead class="masthead-block" style='padding-bottom: unset;'>
          <div class="-fontDisplayXSmallHeading">
            {{title}}
          </div>
          <p class="-sub-heading">{{subheading}}</p>
          <p class="-details">
            {{details}}
          </p>
        </secondary-masthead>
      </div>
      <app-lead-gen></app-lead-gen>
    </div>
  </div>
</div>